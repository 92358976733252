<template>
   <div class="min-h-100 d-flex flex-column h-100">
      <div class="row">
         <div class="col-xxl-12">
            <div class="card card-custom gutter-b">
               <div class="card-header border-0 pt-5 pb-5">
                  <h3 class="card-title align-items-start justify-content-center flex-column">
                     <span class="card-label font-weight-bolder text-dark">{{ $t('GENERAL.FILTER') }}</span>
                     <span class="text-muted mt-3 font-weight-bold font-size-sm">
                        {{
                           Object.keys($route.query).length === 0
                              ? $t('EVACUATION.FILTER_DESC', {
                                   number: evacuations.length
                                })
                              : `${evacuations.length} ${$t('GENERAL.RESULTS')}`
                        }}</span
                     >
                  </h3>
                  <div class="card-toolbar">
                     <router-link
                        v-if="isFiltered()"
                        :to="{ name: $route.name, params: $route.params }"
                        class="btn btn-sm btn-info font-weight-bold text-uppercase px-lg-9 py-lg-3 mr-3"
                     >
                        {{ $t('GENERAL.RESET') }}
                     </router-link>

                     <button
                        type="button"
                        v-on:click="triggerDisplayFilter"
                        class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                     >
                        {{ displayFilter ? $t('GENERAL.HIDE_FILTER') : $t('GENERAL.SHOW_FILTER') }}
                     </button>
                  </div>
               </div>

               <transition name="slide-fade">
                  <div v-if="displayFilter">
                     <form @submit="onSubmit">
                        <div class="card-body">
                           <div class="row">
                              <div class="col-xl-6">
                                 <div class="form-group">
                                    <label>{{ $t('EVACUATION.FILTER_CONTACT') }}</label>
                                    <b-form-input class="form-control form-control-solid" v-model="form.client" />
                                 </div>
                              </div>

                              <div class="col-xl-3">
                                 <div class="form-group">
                                    <label>{{ $t('EVACUATION.START') }}</label>
                                    <b-form-datepicker
                                       class="form-control form-control-solid"
                                       placeholder="JJ/MM/AAAA"
                                       :start-weekday="1"
                                       :hide-header="true"
                                       label-help="Utilisez les touches de votre clavier pour choisir la date"
                                       label-no-date-selected="Aucune date sélectionnée"
                                       autocomplete="off"
                                       v-model="form.start"
                                    ></b-form-datepicker>
                                    <span class="form-text text-muted">{{ $t('ACTION.TIP.START') }}</span>
                                 </div>
                              </div>
                              <div class="col-xl-3">
                                 <div class="form-group">
                                    <label>{{ $t('EVACUATION.END') }}</label>
                                    <b-form-datepicker
                                       class="form-control form-control-solid"
                                       placeholder="JJ/MM/AAAA"
                                       :start-weekday="1"
                                       :hide-header="true"
                                       label-help="Utilisez les touches de votre clavier pour choisir la date"
                                       label-no-date-selected="Aucune date sélectionnée"
                                       autocomplete="off"
                                       v-model="form.end"
                                    ></b-form-datepicker>
                                    <span class="form-text text-muted">{{ $t('ACTION.TIP.END') }}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="card-footer py-3">
                           <div class="row">
                              <div class="col-xxl-12 text-center">
                                 <button
                                    type="submit"
                                    class="btn btn-xs btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                                    :class="sending && 'disabled'"
                                 >
                                    <i v-if="sending" class="fas fa-spinner fa-spin"></i>
                                    {{ $t('GENERAL.FILTER') }}
                                 </button>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </transition>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-xxl-12">
            <div class="card gutter-b">
               <Skeleton3 v-if="loading" />
               <template v-else-if="!loading && evacuations && evacuations.length > 0">
                  <div class="card-body p-0">
                     <EvacuationTable :evacuations="evacuations" :sortBy="sortBy" @change-sort="changeSort" />
                  </div>
               </template>
               <div v-else-if="!loading && evacuations && evacuations.length === 0" class="col-xxl-12">
                  <div class="alert alert-info mx-auto w-50 text-center my-5">
                     {{ $t('GENERAL.NO_DATA') }}
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import Skeleton3 from '@/view/content/skeleton/Skeleton3';
import EvacuationTable from '@/view/pages/evacuation/widget/Table';
import { GET_EVAC_CLIENT } from '@/core/services/store/api-orion/evacuation';

export default {
   data() {
      return {
         currentPage: this.$route.query.page ? this.$route.query.page : 1,
         sortBy: 'date',
         isSortDirDesc: true,
         perPage: 200,
         loading: true,
         sending: false,
         evacuations: [],
         displayFilter: false,
         form: {
            name: this.$route.query.client,
            start: this.$route.query.start,
            end: this.$route.query.end
         }
      };
   },
   components: {
      Skeleton3,
      EvacuationTable
   },
   mounted() {
      this.resetError;
      this.setPageTitle();
      this.getEvacuations();
   },

   methods: {
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.EVACUATIONS'));
      },
      isFiltered() {
         if (Object.keys(this.$route.query).length > 0) {
            return !(this.$route.query.page && Object.keys(this.$route.query).length == 1);
         } else {
            return false;
         }
      },
      async getEvacuations() {
         const form = this.form;
         await this.$store.dispatch(GET_EVAC_CLIENT, { form }).catch((error) => {
            if (error.status === 404) {
               this.$router.push({ name: '404' });
            }
         });
         this.evacuations = this.getEvacClient;
      },
      linkGen(pageNum) {
         const query = {};
         Object.assign(query, this.$route.query);
         query.page = pageNum;
         return {
            name: this.$route.name,
            params: this.$route.params,
            query: query
         };
      },
      changeSort(payload) {
         this.sortBy = payload.dir ? `-${payload.sortBy}` : payload.sortBy;
         this.getEvacuations();
      },
      triggerDisplayFilter() {
         this.displayFilter = !this.displayFilter;
      },
      onSubmit(e) {
         this.sending = true;
         const routerParams = {};
         if (this.$route.query.page) routerParams.page = 1;
         if (this.form.client) routerParams.client = this.form.client;
         if (this.form.start) routerParams.start = this.form.start;
         if (this.form.end) routerParams.end = this.form.end;
         this.$router
            .push({
               name: this.$route.name,
               params: this.$route.params,
               query: routerParams
            })
            .then((this.sending = false))
            .catch(() => (this.sending = false));
         e.preventDefault();
      }
   },
   computed: {
      ...mapMutations({
         resetError: 'resetError'
      }),
      ...mapGetters({
         getEvacClient: 'getEvacClient'
      }),
      ...mapState({
         error: (state) => state.evacuation.error
      })
   },
   watch: {
      evacuations() {
         this.loading = false;
      }
   }
};
</script>
